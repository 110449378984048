import { Col, Container, Link, PageBanner, Row, SocialButton, Stack, Text } from '@zigurous/forge-react'; // prettier-ignore
import React from 'react';
import { Page } from '../components';
import { trackEvent } from '../firebase/analytics';

const metadata = {
  url: 'https://zigurous.com/community',
  title: 'Zigurous • Community',
  description:
    'Zigurous hosts a community Discord server for game developers to collaborate and learn from one another.',
};

const channels = [
  {
    name: 'Welcome',
    items: [
      '📜 Read the #rules of the server',
      '📢 Important server and community #announcements',
      '🗳️ Provide #suggestions for new tutorials and assets',
    ],
    links: [
      { text: '#rules', url: 'https://discord.gg/DdYyWVb' },
      { text: '#announcements', url: 'https://discord.gg/cTTd4XQJhD' },
      { text: '#suggestions', url: 'https://discord.gg/Aq2PRb79Fy' },
    ],
  },
  {
    name: 'Development + Help',
    items: [
      "💬 Share what's on your mind in #general",
      '🧰 Learn about developing with #unity',
      '⌨️ Discuss #programming problems and techniques',
      '🖼️ Share your #artwork and ask for feedback',
      '🧑‍🤝‍🧑 Ask others to #collaborate on projects with you',
    ],
    links: [
      { text: '#general', url: 'https://discord.gg/PhSHtmCtA4' },
      { text: '#unity', url: 'https://discord.gg/V2k23XjA85' },
      { text: '#programming', url: 'https://discord.gg/Kzr37DHXNy' },
      { text: '#artwork', url: 'https://discord.gg/aS3PxVTbsJ' },
      { text: '#collaborate', url: 'https://discord.gg/YxjvYMNSDH' },
    ],
  },
  {
    name: 'Showcase + Sharing',
    items: [
      '⭐ #showcase what you are working on',
      '💎 Post #tutorials you have found to be helpful',
      '🧱 Recommend the best #assets for the community',
    ],
    links: [
      { text: '#showcase', url: 'https://discord.gg/H8ByJDTXcT' },
      { text: '#tutorials', url: 'https://discord.gg/gZXNK3kAHq' },
      { text: '#assets', url: 'https://discord.gg/ucX9afkxpg' },
    ],
  },
];

interface CommunityProps {
  location: Location;
}

export default function Community({ location }: CommunityProps) {
  return (
    <Page className="community" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Text
                as="div"
                className="ml-xxxs"
                marginBottom="xs"
                type="subtitle"
              >
                Discord Server
              </Text>
              <Text as="h1" marginBottom="lg" nowrap type="title-lg">
                Community
              </Text>
              <Text className="text-pretty" marginBottom="lg" type="body-lg">
                Our Discord server is a dedicated place to collaborate with
                other game developers, learn about making games, receive help
                and feedback, and much more. If you are looking for a community
                to grow alongside with, then this is a great place for you.
              </Text>
              <Stack align="center" className="my-sm" spacing="md" wrap>
                <SocialButton
                  link="discord"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Join the Community',
                    });
                  }}
                  shape="pill"
                  size="md"
                  variant="outline"
                >
                  Join the Community
                </SocialButton>
              </Stack>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="mt-xxxl" fluid>
        <Row className="mt-xl">
          <Col>
            <Text as="h2" marginBottom="md" type="title-md">
              Channels
            </Text>
          </Col>
        </Row>
        {channels.map(channel => (
          <Row className="mt-xl" key={channel.name}>
            <Col>
              <Text as="h3" marginBottom="md" type="title-sm">
                {channel.name}
              </Text>
              <ul className="list-bullet list-indent mb-md">
                {channel.items.map((item, index) => {
                  const link = channel.links[index];
                  const words = item.split(' ');
                  return (
                    <li key={item}>
                      {words.map((word, wordIndex) => {
                        if (wordIndex === 0) {
                          return (
                            <span aria-hidden key={wordIndex} role="img">
                              {word}{' '}
                            </span>
                          );
                        } else if (word === link.text) {
                          return (
                            <Link
                              aria-label={link.text.replace('#', '')}
                              external
                              href={link.url}
                              key={wordIndex}
                            >
                              {link.text}{' '}
                            </Link>
                          );
                        } else {
                          return (
                            <React.Fragment key={wordIndex}>
                              {word}{' '}
                            </React.Fragment>
                          );
                        }
                      })}
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        ))}
      </Container>
    </Page>
  );
}
